import { createApp } from 'vue'
import App from './App'
import { router } from './router'
import { ConfigProvider } from 'vant';
//import Vconsole  from 'vconsole'
import 'amfe-flexible'
// const vconsole=new Vconsole()
const app = createApp(App);
app.use(ConfigProvider);
app.use(router);
//app.use(vconsole);
app.mount('#app');