
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/404',
    name: '/404',
    component: () => import('@/page/home'),
  },
  { path: '/:pathMatch(.*)', redirect: '/404' },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('@/page/privacypolicy'),//隐私协议
    meta: {
      title: "隐私协议",
    },
  },
  {
    path: '/fileterms',
    name: 'fileterms',
    component: () => import('@/page/fileterms'),//上传文件条款
    meta: {
      title: "上传文件条款",
    },
  },
  {
    path: '/userterms',
    name: 'userterms',
    component: () => import('@/page/userterms'),//用户协议
    meta: {
      title: "用户协议",
    },
  },
  {
    path: '/cancellation',
    name: 'cancellation',
    component: () => import('@/page/cancellation'),//注销协议
    meta: {
      title: "注销协议",
    },
  },
  {
    path: '/appointmentdetails',
    name: 'appointmentdetails',
    component: () => import('@/page/appointmentdetails'),//预约详情
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/page/Register'),//分享邀请申请页
    meta: {
      title: "受邀登录",
    },
  },
  {
    path: '/Receivedsuccess',
    name: 'Receivedsuccess',
    component: () => import('@/page/Register/Receivedsuccess'),//领取邀请卡成功
    meta: {
      title: "领取成功",
    },
  },
  {
    path: '/Invite',
    name: 'Invite',
    component: () => import('@/page/Invite'),//分享邀请新短链申请页
    meta: {
      title: "受邀登录",
    },
  },
  {
    path: '/Invitesuccess',
    name: 'Invitesuccess',
    component: () => import('@/page/Invite/Invitesuccess'),//分享邀请新短链申请页
    meta: {
      title: "受邀登录",
    },
  },
  {
    path: '/cardInfo',
    name: 'cardInfo',
    component: () => import('@/page/cardInfo'),//会员卡详情
  },
  {
    path: '/ZJactivity',
    name: 'ZJactivity',
    component: () => import('@/page/ZJactivity'),//中骏活动推荐邀请卡
  },
  {
    path: '/ZJsuccess',
    name: 'ZJsuccess',
    component: () => import('@/page/ZJactivity/ZJsuccess'),//中骏活动领取成功
  },

  {
    path: '/serviceReport',
    name: 'serviceReport',
    component: () => import('@/page/serviceReport/index'),//中骏活动领取成功
  },
  {
    path: '/ZJReceived',
    name: 'ZJReceived',
    component: () => import('@/page/ZJactivity/ZJReceived'),//中骏活动已领取
  },
  {
    path: '/findchannel',
    name: 'findchannel',
    component: () => import('@/page/findchannel'),//发现频道
    meta: {
      title: "发现频道",
    },
  },
  {
    path: '/finddetail',
    name: 'finddetail',
    component: () => import('@/page/findchannel/finddetail'),//发现详情
  },
  {
    path: '/discoverList',
    name: 'discoverList',
    component: () => import('@/page/discover/index'),//发现列表页面
  },
  {
    path: '/discoverArticle',
    name: 'discoverArticle',
    component: () => import('@/page/discover/article'),//发现详情
  },

  {
    path: '/discoverdetail',
    name: 'discoverdetail',
    component: () => import('@/page/discover/detail'),//发现详情
  },
  {
    path: '/THSactivity',
    name: 'THSactivity',
    component: () => import('@/page/THSactivity'),//同花顺推荐邀请卡
  },
  {
    path: '/THSReceived',
    name: 'THSReceived',
    component: () => import('@/page/THSactivity/THSReceived'),//同花顺已领取
    meta: {
      title: "领取成功",
    },
  },
  {
    path: '/THSsuccess',
    name: 'THSsuccess',
    component: () => import('@/page/THSactivity/THSsuccess'),//同花顺领取成功
    meta: {
      title: "领取成功",
    },
  },
  {
    path: '/THSanniversary',
    name: 'THSanniversary',
    component: () => import('@/page/THSanniversary'),//同花顺基金十周年庆典活动
  },
  {
    path: '/THSanniversarysuccess',
    name: 'THSanniversarysuccess',
    component: () => import('@/page/THSanniversary/success'),//同花顺基金十周年庆典活动领取成功
    meta: {
      title: "领取成功",
    },
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/page/download'),//下载页
    meta: {
      title: "橙杏健康下载",
    },
  },
  {
    path: '/dl',
    name: 'dl',
    component: () => import('@/page/downloadapp'),//下载页
    meta: {
      title: "橙杏健康下载",
    },
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/page/article'),//文章详情
    meta: {
      title: "",
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/page/payment'),//支付
    meta: {
      title: "支付",
    },
  },
  {
    path: '/purchasecard',
    name: 'purchasecard',
    component: () => import('@/page/purchasecard'),//购买会员
    meta: {
      title: "服务权益说明",
    },
  },
  {
    path: '/confirmorder',
    name: 'confirmorder',
    component: () => import('@/page/purchasecard/confirmorder'),//订单确认
    meta: {
      title: "订单确认",
    },
  },
  {
    path: '/paylogin',
    name: 'paylogin',
    component: () => import('@/page/purchasecard/paylogin'),//购买登录
    meta: {
      title: "",
    },
  },
  {
    path: '/paysuccess',
    name: 'paysuccess',
    component: () => import('@/page/purchasecard/paysuccess'),//购买成功
    meta: {
      title: "购买支付",
    },
  },
  {
    path: '/payerror',
    name: 'payerror',
    component: () => import('@/page/purchasecard/payerror'),//购买失败
    meta: {
      title: "购买支付",
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/page/introduce/about'),//关于我们
    meta: {
      title: "橙杏健康",
    },
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('@/page/card'),//卡片购买商品
    meta: {
      title: "会员权益",
    },
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: () => import('@/page/meeting'),//视频会议
    meta: {
      title: "",
    },
  },
  {
    path: '/meetingdetail',
    name: 'meetingdetail',
    component: () => import('@/page/meeting/detail'),//视频会议详情
    meta: {
      title: "会议详情",
    },
  },
  {
    path: '/d',
    name: 'd',
    component: () => import('@/page/meeting/d'),//视频会议详情
    meta: {
      title: "会议详情",
    },
  },
  {
    path: '/appceshi',
    name: 'appceshi',
    component: () => import('@/page/appceshi/'),//视频会议详情
    meta: {
      title: "ceshi",
    },
  },
  {
    path: '/VIPInvitation',
    name: 'VIPInvitation',
    component: () => import('@/page/VIPInvitation'),//贵宾邀请
    meta: {
      title: "橙杏贵宾邀请",
    },
  },
  {
    path: '/VIPsuccess',
    name: 'VIPsuccess',
    component: () => import('@/page/VIPInvitation/success'),
    meta: {
      title: "橙杏贵宾邀请",
    },
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/page/VIPInvitation/info'),
    meta: {
      title: "修改身份信息",
    },
  },
  {
    path: '/expire',
    name: 'expire',
    component: () => import('@/page/VIPInvitation/expire'),
    meta: {
      title: "橙杏健康",
    },
  },
  
  {
    path: '/tobeopened',
    name: 'tobeopened',
    component: () => import('@/page/VIPInvitation/tobeopened'),
    meta: {
      title: "企业定制服务",
    },
  },

  {
    path: '/notopen',
    name: 'notopen',
    component: () => import('@/page/VIPInvitation/notopen'),
    meta: {
      title: "超级邀请",
    },
  },

  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('@/page/questionnaire/index'),
    meta: {
      title: "健康问卷",
    },
  },

  {
    path: '/questionnaireV2',
    name: 'questionnaireV2',
    component: () => import('@/page/questionnaireV2/index'),
    meta: {
      title: "健康问卷",
    },
  },
  {
    path: '/questionnairestate',
    name: 'questionnairestate',
    component: () => import('@/page/questionnaireV2/state'),
    meta: {
      title: "提交状态",
    },
  },
  
  {
    path: '/intention',
    name: 'intention',
    component: () => import('@/page/intention/index'),
    meta: {
      title: "橙杏健康",
      keepAlive: true,
    },
  },

  {
    path: '/sound',
    name: 'sound',
    component: () => import('@/page/intention/sound'),
    meta: {
      title: "录制音频",
    },
  },
  {
    path: '/showdocx',
    name: 'showdocx',
    component: () => import('@/page/showdocx/index'),
    meta: {
      title: "",
    },
  },
  {
    path: '/customer_servicev2',
    name: 'customer_servicev2',
    component: () => import('@/page/customer_servicev2/index'),
    meta: {
      title: "",
    },
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/page/question/index'),
    meta: {
      title: "常见问题",
    },
  },
  {
    path: '/healthproject',
    name: 'healthproject',
    component: () => import('@/page/healthproject/index'),
    meta: {
      title: "健管项目",
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});

export { router };

