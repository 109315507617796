import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_KeepAlive, {
      include: ['intention']
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
    _: 1
  })]);
}